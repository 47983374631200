import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

type Props = {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const PrivacyPolicyPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <SEO title="privacy policy" />
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdqR9VVCbfYGVAdmLWe9bzWtmFZpuFiFcQn_JInFai7Z3Zj4g/viewform?embedded=true"
        width="640"
        height="721"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        読み込んでいます…
      </iframe>
    </Layout>
  )
}

export default PrivacyPolicyPage
